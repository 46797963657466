.meeting.wrapper {
  background: transparent;
  height: 100%;
}

video {
  max-width: unset;
}

.meeting .ag-header {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meeting .ag-main {
  height: calc(100% + 1px);
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.ag-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 12px;
}